import Text from "@/client/components/atoms/text"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/client/components/atoms/ui/tabs"
import { CrossIcon } from "@/client/components/assets"
import Profile from "@/client/components/molecules/profile"
import { PlanView } from "@/client/components/molecules/payment-plan"
import { UserBO } from "@/server/features/user/bo"

type TabLayoutProp = {
	onClose: React.MouseEventHandler<HTMLButtonElement>
	title: string
	account: UserBO | undefined
	isLoading: boolean
	tabType: "MyPLan" | "Profile"
}

const TABS = [
	{
		name: "Profile",
		value: "profile",
		Content: Profile,
	},
	{
		name: "My Plan",
		value: "my_plan",
		Content: PlanView,
	},
]

const AccountTabLayout = ({ onClose, title, account, isLoading, tabType }: TabLayoutProp) => {
	const activeTab = tabType === "Profile" ? TABS[0].value : TABS[1].value
	return (
		<div className="flex flex-col">
			<div className="sticky top-0">
				<div className="border-b bg-neutral-100 px-6 py-3 md:px-0 md:py-6">
					<div className="flex w-full items-center justify-between gap-x-2 px-4 md:px-12">
						<Text variant="text-semibold" className="text-sm">
							{title}
						</Text>
						<button onClick={onClose}>
							<CrossIcon />
						</button>
					</div>
				</div>
			</div>

			<Tabs defaultValue={activeTab} className="flex h-full w-full flex-col lg:flex-row">
				<TabsList className="flex h-auto w-full grid-cols-2 flex-row content-start justify-start gap-x-2 rounded-none border-r-[1.5px] border-neutral-200 bg-neutral-100 p-2 lg:w-[240px] lg:flex-col lg:p-5">
					{TABS.map(tab => (
						<TabsTrigger
							className="my-2 w-full justify-start rounded-md bg-transparent px-4 py-3 text-xs data-[state=active]:bg-[#f0f0f0] lg:px-7"
							value={tab.value}
							key={`tab-trigger-${tab.value}`}>
							{tab.name}
						</TabsTrigger>
					))}
				</TabsList>
				{TABS.map((tab, index) => (
					<TabsContent
						value={tab.value}
						className="my-0 w-full text-xs"
						key={`tab-content-${index}`}>
						<tab.Content account={account} isLoading={isLoading} />
					</TabsContent>
				))}
			</Tabs>
		</div>
	)
}

export default AccountTabLayout
