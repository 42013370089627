// TODO: Decouple the button component from the svg icons

"use client"

import React, { PropsWithChildren } from "react"
import { cn } from "@/client/lib/utils"
import AiIcon from "/public/svgs/ai.svg"
import {
	CheckMarkSVG,
	AddSVG,
	MessageIcon,
	AddSVGWhite,
	SearchIcon,
	CompanyIcon,
	AnnouncementIcon,
	SearchFillingIcon,
	SettingIcon,
	HomeIcon,
	FilterIcon,
	StarIcon,
	FileIcon,
	DustbinIcon,
	HeartSVG,
	LoginIcon,
	OptionIcon,
	FunnelIcon,
} from "@/client/components/atoms/SVGS"
import { LogoutIcon, MenuIcon, PdfIcon } from "@/client/components/assets"
// TODO: move all svg icons to assets/index

export const svgComponents = {
	SearchIcon,
	CompanyIcon,
	AnnouncementIcon,
	SettingIcon,
	SearchFillingIcon,
	HomeIcon,
	FileIcon,
	StarIcon,
	FilterIcon,
	AiIcon,
	CheckMarkSVG,
	AddSVG,
	DustbinIcon,
	HeartSVG,
	FunnelIcon,
	MessageIcon,
	LoginIcon,
	OptionIcon,
	LogoutIcon,
	AddSVGWhite,
	MenuIcon,
	PdfIcon,
}

export type IconName =
	| "FilterIcon"
	| "StarIcon"
	| "FileIcon"
	| "SearchIcon"
	| "CompanyIcon"
	| "AnnouncementIcon"
	| "SettingIcon"
	| "SearchFillingIcon"
	| "HomeIcon"
	| "AiIcon"
	| "CheckMarkSVG"
	| "AddSVG"
	| "DustbinIcon"
	| "HeartSVG"
	| "FunnelIcon"
	| "MessageIcon"
	| "LoginIcon"
	| "OptionIcon"
	| "LogoutIcon"
	| "AddSVGWhite"
	| "MenuIcon"
	| "PdfIcon"

interface PROPS_TYPE extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	variant: keyof typeof VARIANT_CLASS
	iconName?: IconName | null
}

const VARIANT_CLASS = {
	ActionButton:
		"px-3 py-1 cursor-pointer text-primary text-[12px] font-semibold w-fit hover:bg-brown-100 bg-brown-100 rounded-sm",
	AuthButton: "px-3.5 py-1.5 rounded-md border border-zinc-300",
	SideBarButton:
		"flex text-sm text-neutral-300 font-semibold px-2 py-3 rounded-lg justify-start items-center w-full hover:bg-gray-94 hover:text-black gap-x-2",
	FileButton: "flex justify-start mx-4 w-full gap-x-2 items-center text-neutral-300 text-sm",
	FilterButton:
		"flex justify-center w-fit px-2 py-[1px] gap-x-2 items-center rounded border border-zinc-300 border-dashed",
	InsightsButton:
		"flex justify-center hover:text-primary hover:bg-neutral-brown-100 hover:border-neutral-brown-100 w-fit md:px-2.5 px-2 md:py-1 py-0.5 text-xxs md:text-xs text-neutral-400 font-medium md:gap-x-2 items-center rounded border-[0.5px] border-zinc-300",
	OnlySvgButton: "hover:bg-gray-94 py-2 rounded-lg font-medium",
	Primary:
		"bg-primary text-white text-sm rounded-[4px]  px-[10px] font-medium flex justify-start items-center w-fit gap-x-2 py-[4px]",
	ResetButton: "bg-primary text-white text-sm rounded-[6px] px-[13px] py-[7px] cursor-pointer",
	Secondary:
		"flex justify-center w-fit px-2 py-px gap-x-2 items-center rounded border border-zinc-300 border-dashed",
	FreePlanCTA:
		"text-sm font-semibold flex flex-row items-center px-2 py-1 rounded-md bg-neutral-100 text-primary border border-primary",
	ProPlanCTA:
		"text-sm font-semibold flex flex-row items-center px-2 py-1 rounded-md bg-neutral-100 text-neutral-300 border border-neutral-300",
}
const BASE_CLASS = ""

export default function Button({
	variant,
	children,
	iconName,
	className,
	...props
}: PropsWithChildren<PROPS_TYPE>) {
	const SvgComponent = iconName ? svgComponents[iconName] : null

	return (
		<button className={cn(BASE_CLASS, VARIANT_CLASS[variant], className)} {...props}>
			{SvgComponent ? (
				<div className="flex justify-center">
					<SvgComponent {...props} />{" "}
				</div>
			) : null}
			{children ? (
				<span className="text-full flex h-full w-full flex-row items-center gap-x-2 text-start">
					{children}
				</span>
			) : null}
		</button>
	)
}

export const VARIANT_CLASSES = Object.keys(VARIANT_CLASS)
