import { Info } from "lucide-react"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../atoms/ui/tooltip"

const InfoTooltip = (props: Props) => {
	const { content, className: iconStyles, textStyles } = props
	return (
		<TooltipProvider delayDuration={0}>
			<Tooltip>
				<TooltipTrigger className={iconStyles} asChild>
					<Info className="h-4 w-4 text-slate-700" />
				</TooltipTrigger>
				<TooltipContent className={"px-2 py-1"}>
					<p className={textStyles}>{content}</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}

type Props = {
	className?: string
	textStyles?: string
	content: string
}

export default InfoTooltip
