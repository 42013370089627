"use client"

import React, { createContext, useEffect, Suspense } from "react"
import { useAccount } from "../account"
import { useParams, useRouter, useSearchParams } from "next/navigation"
import { ROUTES } from "@/client/constants/constants"
import { CountryCode } from "@/common/config"
import { CircularPreloader } from "@/client/components/atoms/SVGS"


const PreferredCountryContext = createContext(null)

// This component contains all the logic that uses the hooks
function PreferredCountryContent({ children }: { children: React.ReactNode }) {
	const params = useParams()
	const router = useRouter()
	const searchParams = useSearchParams()
	const currentCountry = params.countryCode as string
	const { account } = useAccount()
	// This check prevents users from accessing /us routes if their preferred_country is 'IN' and vice versa, ensuring they are directed to the appropriate dashboard based on their country preference.
	const shouldRedirectToDashboard =
		currentCountry &&
		account &&
		account.preferred_country.toLowerCase() !== currentCountry.toLowerCase()
	useEffect(() => {
		if (shouldRedirectToDashboard) {
			router.push(ROUTES.DASHBOARD(account.preferred_country as CountryCode))
		}
	}, [account?.preferred_country, currentCountry, searchParams])

	return (
		<PreferredCountryContext.Provider value={null}>{children}</PreferredCountryContext.Provider>
	)
}

// The Provider now wraps PreferredCountryContent inside Suspense
const PreferredCountryProvider = ({ children }: { children: React.ReactNode }) => {
	return (
		<Suspense fallback={<div className="flex justify-center items-center h-screen"><CircularPreloader /></div>}>
			<PreferredCountryContent>{children}</PreferredCountryContent>
		</Suspense>
	)
}

export default PreferredCountryProvider
