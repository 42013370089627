import React from "react"
import { TabsList, TabsTrigger } from "@/client/components/atoms/ui/tabs"

type TabGroupProp = {
	values: string[]
	tabGroupKey: string
}

// to be used within <Tabs> context
const TabGroup = ({ values, tabGroupKey }: TabGroupProp) => (
	<TabsList className="ml-auto h-min items-start p-0">
		{values.map(value => (
			<TabsTrigger
				value={value} // prefixing to make it unique across the app
				key={`${tabGroupKey}-${value}`}
				className="rounded-none border border-zinc-300 text-xs text-neutral-300 first:rounded-l last:rounded-r data-[state=active]:border-primary data-[state=active]:text-primary">
				{tabName(value)}
			</TabsTrigger>
		))}
	</TabsList>
)

function tabName(value: string) {
	if (value.toLowerCase() === "year") {
		return "Annually"
	} else {
		return value[0].toUpperCase() + value.slice(1).toLowerCase() + "ly"
	}
}

export default TabGroup
