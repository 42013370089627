import React from "react"
import Button from "@/client/components/atoms/button"
import Text from "@/client/components/atoms/text"
import { EditIcon } from "@/client/components/assets"
import { Skeleton } from "../../atoms/ui/skeleton"
import { useAccount } from "@/client/store/account"

const ProfileView = ({ onEdit }: { onEdit: () => void }) => {
	const { account, loading, error } = useAccount()

	if (account?.first_name == "" && account?.last_name == "" && account.name != "") {
		const names = account.name.split(" ")
		account.first_name = names[0]
		names.shift()
		account.last_name = names.join(" ")
	}

	return (
		<>
			<div className="flex items-center justify-between border border-neutral-200 px-10 py-2">
				<div>
					<Text variant="text-semibold" className="hidden text-xs lg:flex">
						Profile
					</Text>
				</div>
				<div className="font-medium">
					{loading && !error.status ? null : (
						<Button variant="InsightsButton" className="rounded" onClick={onEdit}>
							<div className="flow-row flex items-center space-x-2.5 px-2 py-0.5">
								<EditIcon />
								<div className="text-xs">Edit</div>
							</div>
						</Button>
					)}
				</div>
			</div>

			{error.status ? (
				<p className="m-4">{error.message}</p>
			) : (
				<>
					<UserProperty title="First Name" value={account?.first_name} loading={loading} />
					<UserProperty title="Last Name" value={account?.last_name} loading={loading} />
					<UserProperty title="Investor Type" value={account?.investor_type} loading={loading} />
					<UserProperty
						title="Market"
						value={account?.preferred_country?.toUpperCase()}
						loading={loading}
					/>
				</>
			)}
		</>
	)
}

export default ProfileView

const UserProperty = ({
	title,
	value = "",
	loading,
}: {
	title: string
	value: string | undefined
	loading: boolean
}) => {
	if (loading) {
		return (
			<div className="px-6 py-2 lg:px-10 lg:py-3">
				<Skeleton className="mt-2 h-[12px] w-[85px]" />
				<Skeleton className="mt-2 h-[12px] w-[160px]" />
			</div>
		)
	} else {
		return (
			<div className="px-6 py-2 lg:px-10 lg:py-3">
				<div className="text-xs text-neutral-300">{title}</div>
				<div className="mt-1 text-xs font-medium text-neutral-400">{value || "--"}</div>
			</div>
		)
	}
}
