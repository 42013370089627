import React, { useState } from "react"
import { Card, CardContent, CardFooter, CardHeader } from "@/client/components/atoms/ui/card"
import Text from "@/client/components/atoms/text"
import { CURRENCY_SYMBOL, CURRENCY_DISPLAY_TEXT } from "@/client/constants"
import { UserCountryType } from "@/client/lib/interface"
import { Check } from "lucide-react"
import { AIIcon } from "@/client/components/assets"
import { Tabs, TabsContent } from "@/client/components/atoms/ui/tabs"
import TabGroup from "@/client/components/atoms/tab-group"
import { DiscountDetailsBO, DiscountType, PlanBO } from "@/server/features/user/bo"
import { cn } from "@/client/lib/utils"
import InfoTooltip from "../info-tooltip"

type PricingCardTemplateProp = {
	planType?: "FREE" | "PRO_UNPAID" | "PRO_PAID"
	countryCode: UserCountryType
	buttonText: string
	isDisabled?: boolean
	onClick: (plan_name: string) => void
	title: string
	description: string[]
	plans: (PlanBO | null)[]
	planTitle?: React.ReactNode
	planState?: React.ReactNode
	loading?: boolean
	discountDetails?: DiscountDetailsBO
}

const Header = ({
	plans,
	currency,
	currencyInText,
	planTitle,
	planState,
	setSelectedTerm,
	discountDetails,
}: {
	plans: PlanBO[]
	currency: string
	currencyInText: string
	planTitle?: React.ReactNode
	planState?: React.ReactNode
	setSelectedTerm: React.Dispatch<React.SetStateAction<string | undefined>>
	discountDetails?: DiscountDetailsBO
}) => (
	<CardHeader className="px-6 py-3 md:px-10 md:py-5">
		<Tabs
			defaultValue={plans[0].plan_period.toLowerCase()}
			className={`flex flex-row justify-between`}
			onValueChange={setSelectedTerm}>
			<>
				{plans?.map((plan: PlanBO | PlanBO, index) => (
					<PricingCardTemplatePricing
						key={`pricing-details-${index}`}
						planTitle={
							planTitle || (
								<Text variant="text-semibold">
									<AIIcon /> Pro
								</Text>
							)
						}
						current_plan_price={plan.current_plan_price}
						user_plan_price={plan.plan_price}
						term={plan.plan_period.toLowerCase()}
						currency={currency}
						currencyInText={currencyInText}
						discountDetails={discountDetails}
					/>
				))}
				{/* Annually | Monthly Buttons */}
				{plans.length > 1 ? (
					<TabGroup
						values={plans.map(plan => plan.plan_period.toLowerCase())}
						tabGroupKey="plans"
					/>
				) : planState ? (
					planState
				) : null}
			</>
			{/* </div> */}
		</Tabs>
	</CardHeader>
)

const PricingCardTemplatePricing = ({
	planTitle,
	current_plan_price,
	user_plan_price,
	term,
	currency,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	currencyInText,
	discountDetails,
}: {
	planTitle: React.ReactNode
	current_plan_price: number
	user_plan_price: number
	term: string
	currency: string
	currencyInText: string
	discountDetails?: DiscountDetailsBO
}) => {
	const isDiscountPlan = current_plan_price != user_plan_price
	const discountPlanHasHeader =
		discountDetails && discountDetails.discount_type === DiscountType.REFERRAL
	return (
		<TabsContent value={term} className="m-0 flex flex-col justify-between">
			<Text variant="text-semibold">{planTitle}</Text>
			<>
				<Text variant="text-thin" className="mt-2 w-full flex-col items-start self-end text-sm">
					<div className="relative">
						<span
							className={`whitespace-nowrap ${
								isDiscountPlan ? "text-thin text-xs line-through" : "text-thin text-sm"
							}`}>
							{currency}
							{current_plan_price}{" "}
						</span>
						<span className={`text-thin text-xs ${isDiscountPlan ? "line-through" : ""}`}>
							/{term.toLowerCase()}{" "}
						</span>
						{discountPlanHasHeader && (
							<span className="absolute -top-2 h-5 w-5">
								<InfoTooltip
									className="ml-2 text-xs"
									content={"You've unlocked 20% off on referral!"}
								/>
							</span>
						)}
					</div>
					{isDiscountPlan && (
						<div className="relative">
							<span className="text-thin whitespace-nowrap text-xs">
								{currency}
								{user_plan_price}
							</span>{" "}
							<span className="text-thin text-xs">/{term.toLowerCase()} </span>
						</div>
					)}
				</Text>
			</>
		</TabsContent>
	)
}

const ActionButton = ({
	buttonText = "",
	isDisabled = false,
	onClick,
	children = null,
	asChild = false,
	loading = false,
}: {
	buttonText: string
	isDisabled?: boolean
	onClick: () => void
	asChild?: boolean
	children?: React.ReactElement | null
	loading?: boolean
}) => (
	<CardContent className="flex justify-center !p-6">
		{asChild ? (
			children
		) : buttonText ? (
			<button
				className={cn(
					"w-3/4 rounded-lg bg-primary py-3 text-xs font-normal leading-4 text-white disabled:bg-zinc-200 disabled:text-zinc-400",
					isDisabled
						? "cursor-not-allowed"
						: loading
							? "animate-pulse cursor-wait"
							: "cursor-pointer"
				)}
				disabled={isDisabled}
				onClick={onClick}>
				{loading ? "Loading..." : buttonText}
			</button>
		) : null}
	</CardContent>
)

const Description = ({
	title = "",
	description = [],
	isDisabled = false,
}: {
	title: string
	description: string[]
	isDisabled?: boolean
}) => (
	<CardFooter className="flex-1 flex-col items-start px-6 py-3 text-xs md:px-10 md:py-5">
		<Text variant="text-semibold" className="mb-2">
			{title}
		</Text>
		<div className="flex flex-col gap-y-2.5 leading-5">
			{description.map((text, index) => (
				<Text variant="text-light" key={`desc-${index}`}>
					<Check className={`h-3 w-3 ${!isDisabled ? "text-primary" : ""}`} strokeWidth={4} />
					{text}
				</Text>
			))}
		</div>
	</CardFooter>
)

Header.displayName = "PricingCardTemplateHeader"
PricingCardTemplatePricing.displayName = "PricingCardTemplatePricing"
ActionButton.displayName = "PricingCardTemplateAction"
Description.displayName = "PricingCardTemplateDescription"

const PricingCardTemplate = ({
	buttonText = "",
	isDisabled = false,
	onClick,
	title,
	description,
	plans,
	countryCode,
	planTitle,
	planState,
	loading = false,
	discountDetails,
}: PricingCardTemplateProp) => {
	const [selectedTerm, setSelectedTerm] = useState<undefined | string>()

	const onSelect = () => {
		let plan_name
		if (selectedTerm) {
			plan_name = plans.filter(plan => plan?.plan_period == selectedTerm?.toUpperCase())[0]
				?.plan_code
		} else {
			plan_name = plans[0]?.plan_code
		}
		if (plan_name) onClick(plan_name)
	}

	const purchasable_plans = plans.filter(plan => plan != null) as PlanBO[]

	return (
		<div className="w-full max-w-[460px] rounded-md border border-b-0 border-neutral-200 first:rounded-b-none last:rounded-t-none last:border-b md:border-r-0 md:first:rounded-r-none md:last:rounded-l-none md:last:border-r">
			<Card className="flex h-full flex-col rounded border-none py-5 shadow-none">
				<Header
					plans={purchasable_plans}
					currencyInText={CURRENCY_DISPLAY_TEXT[countryCode]}
					currency={CURRENCY_SYMBOL[countryCode]}
					planTitle={planTitle}
					planState={planState}
					setSelectedTerm={setSelectedTerm}
					discountDetails={discountDetails}
				/>
				<ActionButton
					buttonText={buttonText}
					isDisabled={isDisabled}
					onClick={onSelect}
					loading={loading}
				/>
				<Description title={title} description={description} isDisabled={isDisabled} />
			</Card>
		</div>
	)
}

export default PricingCardTemplate
